<template>
  <UiModal
    v-model:open="showModal"
    class="lg:w-[68.5rem]"
    type="default"
    @close="emit('close-modal')"
  >
    <template #header="{ handleClose }">
      <header class="relative flex items-center justify-between bg-orange p-5">
        <UiIcon
          class="text-white"
          v-bind="{
            name: 'alert-triangle',
            width: 21,
            height: 18,
          }"
        />

        <div>
          <UiButton
            aria-label="close"
            class="group left-0 ml-auto !block text-white motion-reduce:transition-none"
            variant="icon"
            @click="handleClose"
          >
            <UiIcon
              aria-hidden="true"
              name="close"
              class="transition-colors group-hover:text-gray-300"
              :width="15"
              :height="15"
            />
          </UiButton>
        </div>
      </header>
    </template>
    <template #default="{ handleClose }">
      <M2ReportErrorModalForm
        v-if="!isEmailSent"
        :title="labels.submissionForm.makeSuggestionOrReportError"
        :description="labels.submissionForm.earlyAccessInfo"
        :labels="labels.submissionForm"
        @success="onSuccessfullFormSubmission"
      />

      <div
        v-else
        class="flex flex-col items-center"
      >
        <p class="mb-7 text-center text-2xl font-bold text-secondary">
          <span class="block">{{ labels.successView.thankYou }},</span>
          {{ labels.successView.yourOpinionMatters }}
        </p>

        <UiIcon
          aria-hidden="true"
          name="message-success-outline"
          :width="125"
          :height="122"
          class="mb-7"
        />
        <p class="mb-5">
          {{ labels.successView.afterSubmissionNote }}
        </p>

        <p class="mb-5 self-start">
          {{ labels.successView.thankYou }}!
        </p>

        <div class="py-4 text-center sm:pb-12 sm:pt-9">
          <UiButton
            variant="primary"
            :slim="true"
            :long="true"
            @click="handleClose"
          >
            <p class="text-base font-bold leading-4">
              {{ t('ecom_return_to_page') }}
            </p>
          </UiButton>
        </div>
      </div>
    </template>
  </UiModal>
</template>

<script lang="ts" setup>
const props = defineProps<{
  showModal: boolean
}>()

const emit = defineEmits<{
  (e: 'update:show-modal', value: boolean): void
  (e: 'close-modal'): void
}>()

const { t } = useI18n()

const showModal = computed({
  get: () => props.showModal,
  set: value => emit('update:show-modal', value),
})

const isEmailSent = ref(false)

function onSuccessfullFormSubmission() {
  isEmailSent.value = true
}

const labels = computed(() => ({
  submissionForm: {
    makeSuggestionOrReportError: t(
      'ecom_make_a_suggestion_or_report_an_error',
    ),
    earlyAccessInfo: t('ecom_erawlplug_platform_is_in_an_early_access_program_we_will_be_grateful_for_all_information_concerning_encountered_errors_or_suggestions_for_the_operation_of_our_website'),
    selectReportType: t('ecom_select_type_of_report'),
    selectIfErrorOccuredOnCurrentPage: t(
      'ecom_select_if_an_error_occurred_on_the_current_page',
    ),
    errorDescription: t('ecom_error_description'),
    thankYou: t('thank_you'),
  },
  successView: {
    yourOpinionMatters: t('ecom_your_opinion_matters'),
    afterSubmissionNote: t('ecom_your_request_has_been_sent_our_specialists_will_analyze_it_immediately_upon_receiving_it_if_it_pertains_to_a_bug_report_on_the_platform_we_will_inform_you_when_the_issue_has_been_resolved_if_it_pertains_to_suggestions_regarding_the_functioning_of_the_service_we_will_forward_them_to_the_appropriate_department'),
    thankYou: t('thank_you'),
  },
}))

onUnmounted(() => {
  isEmailSent.value = false
})

watch(
  () => props.showModal,
  () => {
    if (isEmailSent.value) {
      isEmailSent.value = false
    }
  },
)
</script>
